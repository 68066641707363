:root {
  --app-width: 1500px;
  --calendar-body-width: 1176px;
  --color-cyan: #8aebda;
  --color-cyan-dark: #1e7263;
  --color-orange: #ffa985;
  /*--color-blue: rgba(29, 116, 192, 0.3);*/
  --color-blue: rgba(20, 165, 235, 0.5);
}

/***** GLOBAL *****/

.app-container ::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.app-container ::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
}

.app-container ::-webkit-scrollbar-thumb
{
  background-color: #474747;
}

.row-collision {
	background-color: #fccccc;
}

button {
  min-height: 30px;
  padding: 10px;
  box-sizing: border-box;
}

input {
  height: 30px;
}

input:focus, button:focus {
  outline: none;
}

.app-container {
  width: var(--app-width);
  margin: auto;
}

.icon {
  vertical-align: middle;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.row.sm {
  max-height: 50px;
}

.col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.field-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.clickable-icon {
  cursor: pointer;
}

/***** FILTER PANEL *****/
.filter-panel {
  top: 0;
  padding-top: 20px;
  position: fixed;
  background-color: white;
  width: var(--app-width);
  height: 300px;
  z-index: 1;
  box-sizing: border-box;
}

/***** CONFIRMATION MODAL *****/
.confirmation-modal {
  width: 500px;
  height: auto;
}

/***** RADIOLIKE FIELD *****/
.radiolike-option {
  border: 1px solid black;
  height: 30px;
  width: auto;
  min-width: 30px;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 2px;
}

.radiolike-option.selected {
  background-color: var(--color-blue);
  cursor: auto;
}

/***** MINI CALENDAR *****/

.mini-calendar-container {
  min-width: 200px;
}

.react-calendar {
  height: 100%;
  border-top: 1px solid var(--color-blue);
  border-bottom: 1px solid var(--color-blue);
}

.react-calendar abbr {
  text-decoration: none;
}

.react-calendar button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.react-calendar__navigation {

}

.react-calendar__navigation__label {
}

.react-calendar__viewContainer {

}

.react-calendar__month-view {

}

.react-calendar__month-view__weekdays {
  height: 30px;
  border-top: 1px solid var(--color-blue);
  border-bottom: 1px solid var(--color-blue);
  background-color: var(--color-blue);
}

.react-calendar__month-view__weekdays__weekday {
  padding: 2px;
  text-align: center;
}

.react-calendar__month-view__days__day {
  padding: 2px;
  text-align: center;
}

.react-calendar__month-view__days .react-calendar__tile--active {
  background-color: var(--color-orange);
}

.react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth {
  color: grey;
}

.react-calendar__month-view__days__day {
  height: 30px;
}

/***** EVENT DETAIL TABLE *****/
.event-detail-edit-table {
  min-width: 500px;
}

.border-b {
  border-bottom: 1px solid gray;
}

.border-rtb {
	border-right: 1px solid gray;
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
	margin-right: 1rem;
}

.editable-title {
	font-size: 1.25rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	border: 1px solid transparent;
	background-color: transparent;
	width: auto;
	text-align: right;
}

.editable-title:hover {
	border: 1px solid gray;
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}